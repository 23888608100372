<template>
  <ClockingsDetailItemEditCard :confirmation-message="getConfirmationMessage" :title="absenceTitle"
    :update-store-action="getActionString" :update-store-action-payload="getPayload" :date="date" :userId="userId"
    :promises.sync="promises" :returnRoute="returnRoute" :detailRoute="detailRoute">
    <template v-slot:form-fields>
      <v-row justify="start" align="center">
        <v-col lg="4" md="8" sm="8" xs="12">
          <v-autocomplete required v-model="absenceClocking.clockingType" :items="items" label="Afwezigheids type"
            :rules="[v => !!v || 'Afwezigheid moet ingevuld worden']" tabindex="1"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="!clockingId" justify="start" align="center">
        <v-col lg="4" md="8" sm="8" xs="12">
          <DatePicker v-model="endDate" :label="endDateLabel" :min="new Date(date)" />
        </v-col>
      </v-row>
      <v-row justify="start" align="center">
        <v-col lg="4" md="8" sm="8" xs="12">
          <v-autocomplete v-model="absenceDuration" :items="getDurationItems" label="Duur afwezigheid" tabindex="4"
            :disabled="!absenceClocking.clockingType" @change="onAbsenceDurationChange"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="start" align="center">
        <v-col lg="2" md="4" sm="4" xs="6">
          <TimePicker :disabled="disableTimePickers" v-model="absenceClocking.startTime" label="Van" required />
        </v-col>
        <v-col lg="2" md="4" sm="4" xs="6">
          <TimePicker :disabled="disableTimePickers" v-model="absenceClocking.endTime" label="Tot"
            :min="absenceClocking.startTime" required />
        </v-col>
      </v-row>
      <v-row v-if="absenceClocking.clockingType" justify="start" align="center">
        <v-col lg="4" md="8" sm="8" xs="12">
          <v-textarea label="Opmerking" outlined v-model="absenceClocking.comment"
            :rules="[v => (v == null || v.length <= 200) || 'Opmerking kan maximaal uit 200 karakters bestaan']" />
        </v-col>
      </v-row>
    </template>
  </ClockingsDetailItemEditCard>
</template>

<script>
import DatePicker from "@/components/shared/DatePicker.vue";
import routeNames from "@/router/RouteNames";
import ClockingsDetailItemEditCard from "@/views/calendar/forms/ClockingsDetailItemEditCard";
import TimePicker from "../../../components/shared/fields/TimePicker.vue";
import { addMinutes, parse } from 'date-fns';
import AbsenceDuration from "../../../shared/constants/AbsenceDuration";
import ClockingType from "@/shared/constants/ClockingType";
import { parseStringToDate, convertDateToWeekday } from "@/shared/utils/dateUtils";
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";


export default {
  name: "CalendarDetailRegisterAbsence",
  props: {
    clockingId: {
      default: null,
      required: false,
      type: String
    },
    date: {
      default: null,
      required: true,
      type: String
    },
    userId: {
      default: null,
      required: false,
      type: String
    },
    returnRoute: {
      type: Object,
      required: false,
      default: () => ({ name: routeNames.MODIFY_USER_HOURS_DAY })
    },
    detailRoute: {
      type: Object,
      required: false,
      default: () => ({ name: routeNames.TEAM_CALENDAR_DETAIL_USER_EDIT_ABSENCE })
    }
  },
  components: {
    DatePicker,
    ClockingsDetailItemEditCard,
    TimePicker
  },
  data() {
    return {
      ClockingType,
      routeNames: routeNames,
      items: [ClockingType.VACATION, ClockingType.SICK, ClockingType.HOLIDAY, ClockingType.OTHER],
      endDate: this.$route.params.date,
      endDateLabel: "Afwezig tot en met",
      absenceDuration: this.getDefaultAbsenceDuration(),
      promises: [
        Promise.all([this.fetchDayOverview(), this.fetchAbsenceClocking(), this.fetchStartTimePerWeek()]).then(() => this.onAbsenceDurationChange(this.absenceDuration)),
        this.getUserId() ? this.$store.dispatch('usersModule/fetchUser', this.getUserId()).then(data => this.userName = data.name) : ''],
      updatePath: null,
      dayOverview: null,
      absenceClocking: {
        userId: this.getUserId(),
        clockingType: null,
        day: this.date,
        comment: null,
        startTime: null,
        endTime: null,
      },
    }
  },
  created() {
    this.definePromises()
  },
  computed: {
    isRegisterAbsenceForOtherEmployee() {
      // url contains userId param, meaning we are registering/editing absence of another user
      return this.$route.params.userId;
    },
    getUser() {
      return this.$store.state.usersModule.user
    },
    getUserName() {
      if (!this.userName?.firstName || !this.userName?.lastName) {
        return ''
      }
      return `${this.userName.firstName} ${this.userName.lastName}`
    },
    getPayload() {
      const payloadAbsenceClocking = {
        ...this.absenceClocking,
        startTime: DetailedTimeStamp.fromUTCTime(this.absenceClocking?.startTime),
        endTime: DetailedTimeStamp.fromUTCTime(this.absenceClocking?.endTime),
      }
      if (this.endDate !== this.date) {
        const payload = {
          endDate: this.endDate,
          absenceClocking: payloadAbsenceClocking
        }
        return payload
      } else {
        return payloadAbsenceClocking
      }
    },
    disableTimePickers() {
      return !(this.absenceDuration === AbsenceDuration.SPECIFIC)
    },
    getDurationItems() {
      let durationItems = [
        { text: 'Volledige dag', value: AbsenceDuration.FULL },
        { text: 'Voormiddag', value: AbsenceDuration.MORNING },
        { text: 'Namiddag', value: AbsenceDuration.AFTERNOON },
        { text: 'Kies specifieke uren', value: AbsenceDuration.SPECIFIC }
      ]

      if (!this.dayOverview?.expectedHours || this.isEditingAnExistingAbsence) {
        durationItems = [{ text: 'Kies specifieke uren', value: AbsenceDuration.SPECIFIC }]
      }

      return durationItems
    },
    absenceTitle() {
      if (this.isEditingAnExistingAbsence) {
        return this.isRegisterAbsenceForOtherEmployee ? `Bewerk afwezigheid ${this.getUser?.name}` : "Bewerk afwezigheid"
      } else {
        return this.isRegisterAbsenceForOtherEmployee ? `Registreer afwezigheid ${this.getUser?.name}` : "Registreer afwezigheid"
      }
    },
    getConfirmationMessage() {
      if (this.isEditingAnExistingAbsence) {
        return "Afwezigheid werd gewijzigd"
      } else {
        return "Afwezigheid werd toegevoegd"
      }
    },
    getActionString() {
      if (this.isEditingAnExistingAbsence) {
        return "clockingsModule/updateAbsenceClocking"
      } else if (this.endDate === this.absenceClocking.day) {
        return "clockingsModule/createAbsenceClocking"
      } else
        return "clockingsModule/createAbsenceClockings"
    },
    isEditingAnExistingAbsence(){
      return !!this.clockingId
    }
  },
  methods: {
    definePromises() {
      const promises = [this.fetchDayOverview(), this.fetchAbsenceClocking()]
      if (this.isRegisterHoursForOtherEmployee) {
        promises.push(this.fetchUser())
      }
      this.promises = promises
    },
    setUpdatePath(path) {
      this.updatePath = "clockingsModule/" + path
    },
    getUserId() {
      return this.$route.params.userId ? this.$route.params.userId : this.$store.state.authModule.user.id
    },
    fetchDayOverview() {
      return this.$store.dispatch('clockingsModule/fetchDayOverview', {
        date: this.$route.params.date,
        userId: this.getUserId()
      }).then(result => {
        this.dayOverview = result
        this.absenceDuration = this.getDefaultAbsenceDuration()

      })
    },
    fetchStartTimePerWeek() {
      return this.$store.dispatch('clockingsModule/fetchStartTimePerWeekFilledNulls',
        this.getUserId()).then(result => this.startTimeMap = result)
    },
    fetchAbsenceClocking() {
      if (this.isEditingAnExistingAbsence) {
        return this.$store.dispatch('clockingsModule/fetchClockingById', this.clockingId).then(result => {
          this.absenceClocking = {
            ...result,
            startTime: new Date(result?.startTime?.localTime),
            endTime: new Date(result?.endTime?.localTime),
          }
        })
      } else {
        return null
      }
    },
    onAbsenceDurationChange(newValue) {
      let newStartTime = parse(this.date, "yyyy-MM-dd", new Date())
      let newEndTime = parse(this.date, "yyyy-MM-dd", new Date())

      let dateWithTimeString = this.startTimeMap.weekdaysWithTime[convertDateToWeekday(newStartTime)];

      let dateWithTime = parseStringToDate(dateWithTimeString);

      //When absence duration changes, set the default time values 
      switch (newValue) {
        case AbsenceDuration.FULL:
          newStartTime.setHours(dateWithTime.getHours())
          newStartTime.setMinutes(dateWithTime.getMinutes())
          newEndTime.setHours(dateWithTime.getHours(), this.dayOverview?.expectedHours * 60)
          newEndTime.setMinutes(dateWithTime.getMinutes())
          break;
        case AbsenceDuration.MORNING:
          newStartTime = addMinutes(newStartTime, 8 * 60)
          newEndTime = addMinutes(newEndTime, (8 + (this.dayOverview?.expectedHours / 2)) * 60)
          break;
        case AbsenceDuration.AFTERNOON:
          newStartTime = addMinutes(newStartTime, 12 * 60)
          newEndTime = addMinutes(newEndTime, (12 + (this.dayOverview?.expectedHours / 2)) * 60)
          break;
        case AbsenceDuration.SPECIFIC:
          if (this.isEditingAnExistingAbsence) {
            return
          }
          newStartTime.setHours(dateWithTime.getHours())
          newStartTime.setMinutes(dateWithTime.getMinutes())
          newEndTime.setHours(dateWithTime.getHours(), this.dayOverview?.expectedHours * 60)
          newEndTime.setMinutes(dateWithTime.getMinutes())
          break;
        default:
          new Error(`Default startTime and endTime not configured for: ${newValue}`)
          break;
      }
      this.absenceClocking.startTime = newStartTime
      this.absenceClocking.endTime = newEndTime
    },
    getDefaultAbsenceDuration() {
      if (!this.isEditingAnExistingAbsence && this.dayOverview?.expectedHours) {
        return AbsenceDuration.FULL
      } else {
        return AbsenceDuration.SPECIFIC
      }
    }
  }
}
</script>